const $ = jQuery;

export default class Menu {
    constructor(options = null) {
        this.$body = $("body");
        this.$header = $("header");
        this.$menu = $("#menu-primary");
        this.$burger = $("#burger");
        this.$lastWindowScrollTop = 0;
        this.$submenu = $(".menu-item-has-children>a");
    }

    init() {
        this.__menu();
        this.__search();
    }

    __search() {
        $("body").on("click", ".mobileSearch", (event) => {
            event.preventDefault();
            const target = event.currentTarget;

            $(".searchFormMobileContainer").slideToggle(100);
            target.classList.toggle("open");
        });
    }

    __menu() {
        $("body").on("click", "#burger", (event) => {
            event.preventDefault();
            this.$burger.toggleClass("active");

            this.$menu.toggleClass("open");
            this.$header.toggleClass("fixed");

            $(".menu-primary-container").slideToggle();

            if (!this.$burger.hasClass("active")) {
                this.$body.removeClass("non-ios-noscroll");
                window.scrollTo({
                    top: this.$lastWindowScrollTop,
                    behavior: "instant",
                });
            } else {
                this.$lastWindowScrollTop = $(window).scrollTop();
            }

            $("body").on("click", ".menu-item-has-children", (event) => {
                event.preventDefault();

                const target = event.currentTarget;
                const parentElement = $(target).parent();
                $(parentElement).children("a.megaMenu").toggleClass("open");
                $(parentElement).children(".sub-menu").toggleClass("open");
            });

            $("body").on("click", ".sub-menu .submenuWrapper", (event) => {
                event.preventDefault();

                const target = event.currentTarget;
                $(target).children(".heading").toggleClass("open");
                $(target).children(".sub-menu").toggleClass("open");
            });
        });
    }
}
